import React from "react";
import PropTypes from "prop-types";

import PriceFormatter from "../../../../components/priceFormatter";

function OrderDetailPriceSummary({ order }) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                    <div style={{ width: "150px" }}>
                        <b>BTW</b>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            <PriceFormatter price={ order.totalTaxPrice }/>
                        </b>
                    </div>
                </div>
                { order.shippingPrice > 0 && (
                    <div className="d-flex justify-content-end text-right mb-2 text-muted">
                        <div style={{ width: "150px" }}>
                            <b>Verzendkosten</b>
                        </div>
                        <div style={{ width: "150px" }}>
                            <b>
                                <PriceFormatter price={ order.shippingPrice }/>
                            </b>
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                    <div style={{ width: "150px" }}>
                        <b>Totaalbedrag</b>
                        <br/>
                        <span className="text-muted">exclusief btw</span>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            <PriceFormatter price={ order.totalPriceExclTax }/>
                        </b>
                    </div>
                </div>
                <div className="d-flex justify-content-end text-right">
                    <div style={{ width: "150px" }}>
                        <b>Totaalbedrag</b>
                        <br/>
                        <span className="text-muted">inclusief btw</span>
                    </div>
                    <div style={{ width: "150px" }}>
                        <b>
                            <PriceFormatter price={ order.totalPrice }/>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    )
}
OrderDetailPriceSummary.propTypes = {
    order: PropTypes.object
}

export default React.memo(OrderDetailPriceSummary);
