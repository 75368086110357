import React from "react";
import PropTypes from "prop-types";

import ProductImageDataCard from "../../../../components/ProductImageDataCard";
import ProductTypePill from "../../../../components/ProductTypePill";
import PriceFormatter from "../../../../components/priceFormatter";

function OrderDetailProductsList({ order }) {
    if(!order) {
        return null;
    }
    return order.products.map((product) => {
        const image = product.images.length === 0 ? null : product.images[0];
        return (
            <div className="mb-2" key={ product.id }>
                <ProductImageDataCard image={ image }>
                    <div className="d-flex flex-column h-100">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-1">
                                { product.name }
                            </h5>
                            <p className="mb-1">
                                <ProductTypePill product={ product }/>
                            </p>
                        </div>
                        <div>
                            <div className="d-flex flex-row">
                                <div className="flex-grow-1 text-muted d-none d-lg-block">
                                    <b className="mr-2">
                                        { product.amount }x
                                    </b>
                                    <b>
                                        <PriceFormatter price={ product.orderPrice }/>
                                    </b>
                                </div>
                                <div>
                                    <b>
                                        <PriceFormatter price={ product.amount * product.orderPrice }/>
                                    </b>
                                </div>
                            </div>
                        </div>
                    </div>
                </ProductImageDataCard>
            </div>
        )
    });
}
OrderDetailProductsList.propTypes = {
    order: PropTypes.object
}

export default React.memo(OrderDetailProductsList);
